/* src/App.css */

:root {
  --bs-body-bg: #f5f5f5;          /* Change background color of the body */
  --bs-navbar-bg: #f5f5f5;        /* Navbar background color */
  --bs-navbar-color: #000;     /* Navbar text color */
  --bs-nav-link-color: #343a40;   /* Nav link color */
  --bs-nav-link-hover-bg: #f1eeff; /* Nav link hover background */
  --bs-primary: #343a40;          /* Primary color */
  --bs-secondary: #6c757d;        /* Secondary color */
  /* Add more overrides as needed */
}

.btn-primary {
  --bs-btn-bg: #C737A0; /* Default background color */
  --bs-btn-border-color: #C737A0; /* Default border color */
  --bs-btn-hover-bg: #8C3DFF; /* Hover background color */
  --bs-btn-hover-border-color: #8C3DFF; /* Hover border color */
  --bs-btn-active-bg: #004085; /* Active background color */
  --bs-btn-active-border-color: #004085; /* Active border color */
}

.table {
  --bs-table-bg: #ffffff;           /* Table background */
  --bs-table-accent-bg: #ffffff;    /* Table row background */
  --bs-table-hover-bg: #f1eeff;     /* Table hover background */
  --bs-table-border-color: #dee2e6; /* Table border color */
  --bs-table-striped-bg: #f2f2f2;   /* Table striped row background */
}


/* Optional: Customize focus shadow */
.btn-primary:focus {
  --bs-btn-focus-shadow-rgb: 38, 143, 255; /* Focus shadow color */
}

.btn-secondary {
  background-color:#f5f5f5;
  font-weight:500;
   color: var(--bs-primary);
	border:none;
}

.top-bar {
  padding: 10px;
  background-color: var(--bs-body-bg) !important;
}

.bg-light {
	background-color: #ffffff !important;
}

.font-small {
	  font-size: 1rem;
}

.corners-light {
	border-radius: 4px;
}

.left-menu .nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  /*color: var(--bs-nav-link-color) !important;*/
  color: #343a40;
}

.left-menu .nav-link:hover {
  background-color: var(--bs-nav-link-hover-bg) !important;
}

.left-menu .nav-link i {
  font-size: 1rem;
}

.left-menu {
  min-height: 83vh;
  border-right:solid 1px var(--bs-primary);
  padding-right:1rem;
  /*  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3); /* Light shadow */
}

.inline-link {
  color: #007bff; /* Example color */
  display:inline;
  font-weight: bold;
  text-decoration: none;
  padding: 0;
}

.inline-link:hover {
  color: #0056b3; /* Example hover color */
  text-decoration: underline; /* Example hover effect */
}

.main-container {
 min-height: 100%;
 font-size:0.9rem;
 /*  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3); /* Light shadow */

}



.main-container h1 {
  color: #343a40;

}

.auth-container {
	box-shadow: 0 0px 20px rgba(0, 0, 0, 0.5); /* Light shadow */
}

.auth-footer {
	width:100%;
}

.bi {
	font-size:1.2rem;
}

.logo_text {
	width:8rem;
}

.body-wrapper {
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:10%;
	background-color:#ffffff;
	opacity:0;
	z-index:-1;
}

.app-container {
  margin: 20px auto; /* Adds margin around the container */
  max-width: 95%; /* Optionally control the width */
  background-color: #fff; /* Optional: background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for a raised effect */
  border-radius: 0.5rem; /* Optional: rounded corners */
  padding: 1rem; /* Optional: padding inside the container */
  padding-bottom: 2rem;
}

.mt-n1 {
    margin-top: -1rem;
}
.mt-n2 {
    margin-top: -2rem;
}

.mt-n3 {
    margin-top: -3rem;
}

.mr-n3 {
    margin-right: -3rem;
}

.border-purple {
	box-shadow:	 0 0.5rem 1rem rgba(0, 0, 0, 0.15),
	  0 0 0 3px #C737A0
 !important; 
}
.clickable {
	cursor:pointer;
}

.clickable input {
	cursor:pointer;
}

tbody tr.active-key-row {
  background-color: #FFE9FA !important;
}

.no-accent-bg {
  --bs-table-accent-bg: transparent;
    --bs-table-bg: transparent;
}

.product-card {
  display: flex;
  flex-direction: column;
  padding:1rem;
  padding-bottom:2.5rem;
  height: 100%; /* Ensure all cards are the same height */
}

.product-name {
	margin-top:1rem;

}

.product-price {
	margin-top:2rem;
	margin-bottom:2rem;
}

.product-features {
  flex-grow: 1; /* Allow features to fill remaining space */
  display: flex;
  flex-direction: column; /* Ensure features are in separate lines */
  gap: 0.5rem; /* Add spacing between features */
  margin-top:1rem;
  margin-bottom:2.5rem;
  text-align:start;
  padding-left:8%;
  padding-right:8%;
}


.product-btn {
	margin-left:8%;
  	margin-right:8%;
  	width:86%;
  	padding-top:0.75rem;
  	padding-bottom:0.75rem;
}

.feature-item {
  line-height: 1; /* Improve readability */
}

.mt-auto {
  margin-top: auto; /* Push button to the bottom of the card */
}

.empty-layout-wrapper {
  background: url('https://app.muzaic.ai/img/slice2_1.svg') no-repeat center center;
  background-size: cover; /* Ensure the background scales properly */
  min-height: 100vh; /* Make sure the background covers at least the viewport */
  height: auto; /* Allow the container to grow beyond the viewport if needed */
}

.top-n3 {
	top:0;
}

.mt-8 {
	margin-top:8rem;
}
